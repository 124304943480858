.title-box {
    width: 90%;
    text-align: center;
    margin: 40px auto;
}

#item-cont-re {
    font-family: "メイリオ", Meiryo, "ヒラギノ角ゴPro W3",
        "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 100%;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    background: #fff;
}

#item-cont-re .title-box h2 {
    border-bottom: 1px solid #ddd;
}

#item-cont-re .title-box h3 {
    border-bottom: 1px solid #ddd;
}

#item-cont-re .title-box h4 {
    font-size: 1.5em;
    color: #333;
    border-bottom: 1px solid #ddd;
}

#item-cont-re .title-box h5 {
    font-size: 1.25em;
    color: #333;
    border-bottom: 1px solid #ddd;
}

#item-cont-re .title-box p {
    font-size: 1.2em;
    color: #666;
    margin: 16px auto;
}

#item-cont-re .item-caption {
    width: 78%;
    margin: 0 auto;
}

#item-cont-re .item-caption p {
    font-size: 1em;
    color: #555;
    line-height: 1.8em;
    text-align: left;
    display: inline-block;
}

#item-cont-re .item-caption img {
    width: 90%;
}

.img-box {
    width: 100%;
    margin: auto;
}

.img-box img {
    max-width: 90%;
    margin: auto;
}

.product_link {
    width: 90%;
    list-style: none;
}

.product_link li {
    padding: 5px 8px;
    display: inline-block;
    margin-right: 1px;
}

.prod_img {
    width: 200px;
}

.additional_menu {
    display: inline-block;
}

.additional_menu p {
    text-align: center;
    padding: 5px;
}

.additional_menu img {
    width: 200px;
}

table.spec {
    text-align: left;
    border: 1px solid #ccc;
    margin: 8% auto;
    width: 100%
}

table.dataframe {
    text-align: left;
    border: 1px solid #ccc;
    margin: 8% auto;
    width: 100%;
}

.spec th,
.dataframe th {
    color: #fff;
    border: 1px solid #ccc;
    background: #78909C;
    text-align: center;
    font-weight: bolder;
    padding: 10px;
    width: 20%;
}

.spec td,
.dataframe td {
    border: 1px solid #ccc;
    padding: 10px;
    max-width: 40%;
}

.spec.ind,
.dataframe.ind {
    width: 6em;
}

.spec img,
.dataframe img {
    width: 70%;
}

.inline-ul {
    width: 100%;
    display: inline-flex;
    justify-content: center;
}

.inline-li {
    min-width: 100px;
    max-width: 300px;
    width: 33%;
    display: inline-block;
    justify-content: center;
}

.inline-li div {
    padding: auto;
}

.inline-li img {
    width: 100%;
}

#link_txt {
    width: 85%;
    text-align: center;
}

.inline-ul p {
    width: 85%;
}