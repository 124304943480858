/* Required height of parents of the Half Page Carousel for proper displaying carousel itself */
html,
body {
  height: 100vh;
  width: 100vw;
}

.view {
  height: 80vh;
  width: 100vw;
}

/* Half Page Carousel itself*/
/*.carousel {
  height: 50%;
}

.carousel .carousel-inner {
  height: 100%;
}

.carousel .carousel-inner .carousel-item,
.carousel .carousel-inner .active {
  height: 100%;
}*/

/* Adjustment for mobile devices*/
/*@media (max-width: 776px) {
  .carousel {
    height: 100%;
  }
}*/

.overlay-container {
  position: relative;
  display: inline-block;
  /* 要素のサイズを画像に合わせるため */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  /* 半透明の白 */
}


.carousel-caption .title {
  font-weight: bolder;
  color: #0009;
}

.carousel-caption .description {
  font-weight: bolder;
  color: #0009;
  min-height: 3rem;
}

.carousel-image {
  min-height: 12rem;
  width: 80%;
}

.blue-text {
  color: #2196f3 !important;
}

.mdb-color.lighten-3 {
  background-color: #929fba !important;
}

.font-small {
  font-size: .9rem
}

/* Footer color for sake of consistency with Navbar */
footer.page-footer {
  color: #fff;
  background-color: #929FBA;
  position: sticky;
  top: 100vh;
  font-size: 0.8rem;
}

footer.page-footer a {
  color: #fff;
}

footer.page-footer .footer-copyright {
  overflow: hidden;
  color: rgba(255, 255, 255, .6);
  background-color: rgba(0, 0, 0, .2);
}


.bg-pale-grey {
  background-color: #ECEFF1;
}

.bg-blue-grey-400 {
  background-color: #78909C;
}

.bg-pale-orange-l1 {
  background-color: #FFCCBC;
}

.bg-pale-orange-l2 {
  background-color: #FBE9E7;
}

.bg-pale-cyan-l1 {
  background-color: #B2EBF2;
}

.bg-pale-cyan-l2 {
  background-color: #E0F7FA;
}

.bg-deep-blue {
  background-color: #3f729b;
}


/* 商品検索text input*/
#product-search #div_id_search {
  margin-bottom: 0px !important;
}