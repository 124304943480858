#loading {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background-color: #fff;
	opacity: 0.8;
}

#loading .loadingMsg {
	text-align: center;
	vertical-align: middle;
	padding-top: 140px;
	min-height: 100px;
	z-index: 100;
}